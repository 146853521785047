
import Vue from "vue";
import Dashboard from "@/components/User/Dashboard.vue";

export default Vue.extend({
  name: "AdminWidgetMesages",
  components: {
    Dashboard
  },
  props: {},
  data() {
    return {};
  }
});
